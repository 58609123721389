import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Script from "next/script";
import { useContext, useEffect, useState } from "react";

import { TiWarningOutline } from "react-icons/ti";

import { site } from "config";
import DatadogInit from "utils/monitoring/datadog";

import Header from "components/layoutComponents/Header/Header";
import { UserContext } from "contexts/user";
import styles from "./Layout.module.scss";
const Footer = dynamic(
  () => import("components/layoutComponents/Footer/Footer")
);
const AuthStatus = dynamic(() => import("./auth/clerk/AuthStatus"));
// const CookiePrompt = dynamic(() => import("./CookiePrompt"));

const Button = dynamic(() => import("components/ui/buttons/Button/Button"));

const Layout = ({
  children,
  seo,
  customStyles,
  showEmailSignup,
  layoutData,
  showFooter = { mobile: true, desktop: true },
}) => {
  const [isWebview, setIsWebview] = useState(false);
  const [showWebviewWarning, setShowWebviewWarning] = useState(true);
  const router = useRouter();

  const { setUserData } = useContext(UserContext);

  useEffect(() => {
    const detectWebview = sessionStorage.getItem("isWebview");
    if (detectWebview) {
      setIsWebview(true);
    }
  }, []);

  const APP_DESCRIPTION =
    "WhereNext Experiences is a travel company providing impactful experiences across the globe with a focus on volunteering and animal conservation.";
  // const APP_NAME = "WhereNext?";
  const APP_TITLE = "WhereNext Experiences";

  const canonicalURL = seo?.canonicalURL
    ? seo?.canonicalURL
    : site + router.pathname;

  const metaMedia = seo?.metaMedia?.data?.attributes || seo?.metaMedia;

  const isProduction =
    process.env.NEXT_PUBLIC_ENV.toLowerCase() === "production";

  return (
    <>
      <NextSeo
        title={seo?.metaTitle || seo?.title || APP_TITLE}
        description={
          seo?.metaDescription || seo?.description || APP_DESCRIPTION
        }
        canonical={canonicalURL}
        noindex={
          seo?.noindex || seo?.preventIndexing || !isProduction ? true : false
        }
        nofollow={seo?.nofollow || !isProduction ? true : false}
        openGraph={{
          title: seo?.metaTitle || seo?.title || APP_TITLE /*APP_NAME*/,
          description:
            seo?.metaDescription || seo?.description || APP_DESCRIPTION,
          url: canonicalURL,
          type: "website",
          locale: "en_US",
          images: [
            {
              url: metaMedia?.url,
              width: 1200,
              height: 628,
              alt: metaMedia?.alternativeText,
            },
          ],
        }}
        twitter={{
          site: "@wherenext_to",
          cardType: "summary_large_image",
        }}
      />
      <DatadogInit />

      <div className={`${styles.headerArea}`}>
        {layoutData?.headerData && (
          <Header
            headerData={layoutData?.headerData}
            socialLinks={layoutData?.footerData?.socialLinks}
          />
        )}
      </div>

      <div className={`${styles.content}`} style={customStyles}>
        {children}
      </div>
      {/* <CookiePrompt /> */}
      {/* <AuthStatus /> */}
      <Footer
        showFooter={showFooter}
        showEmailSignup={showEmailSignup}
        footerData={layoutData?.footerData}
      />

      {isWebview && showWebviewWarning && (
        <WebviewWarningBanner
          handleClose={() => {
            setShowWebviewWarning(false);
            const body = document.querySelector("body");
            body.classList.remove("wn-show-webview-warning");

            setUserData((prev) => {
              return { ...prev, webviewWarningClosed: true };
            });
          }}
        />
      )}
      {["development", "local"].includes(process.env.NEXT_PUBLIC_ENV) && (
        <Script
          src="//js.hs-scripts.com/20685762.js"
          id="hs-script-loader"
          strategy="lazyOnload"
        />
      )}
      <div id="portal" />
    </>
  );
};
export default Layout;

function WebviewWarningBanner({ handleClose }) {
  const { userData } = useContext(UserContext);
  const { isUsingWebview, webviewWarningClosed } = userData;

  if (!isUsingWebview || webviewWarningClosed) {
    return null;
  }

  return (
    <div className={styles.webviewWarning}>
      <div className={`${styles.content}`}>
        <div className={`${styles.icon}`}>
          <TiWarningOutline color="var(--light-text)" size={60} />
        </div>
        <p className={`${styles.text}`}>
          Some of this site&apos;s features may not work as expected when opened
          within another app. For the best experience, please open in a separate
          browser.
        </p>
      </div>
      <div className={`${styles.close}`}>
        <Button onClick={handleClose} classes={["close-webview-warning"]}>
          Okay
        </Button>
      </div>
    </div>
  );
}
