import React, { useContext, useState, useEffect } from "react";
import Image from "next/image";

import { CurrencyContext } from "contexts/currency";
import { getCookie } from "utils/index";

import { currencies } from "lib/data/currencies.js";

import styles from "./CurrencySwitcher.module.scss";

const CurrencySwitcher = ({ buttonStyles, listStyles }) => {
  const currencyContext = useContext(CurrencyContext);
  const currency = currencyContext.currency;

  const changeCurrency = currencyContext.changeCurrency;

  const [currentCurrency, setCurrentCurrency] = useState(currency);
  const [switcherIsOpen, setSwitcherIsOpen] = useState(false);

  const setCurrencyCookie = (currency) => {
    document.cookie = `currency=${encodeURIComponent(
      JSON.stringify(currency)
    )}; SameSite=Strict; Secure expires=${new Date(
      new Date().getTime() + 60 * 60 * 17 * 1000 * 365
    ).toUTCString()}; path=/`;
  };

  useEffect(() => {
    const currencySetup = () => {
      const defaultCurrency = currencies.usd;

      if (getCookie("currency") === "undefined") {
        setCurrencyCookie(defaultCurrency);
      }

      const initialCurrency =
        JSON.parse(getCookie("currency")) ?? defaultCurrency;

      setCurrentCurrency(initialCurrency);
      setCurrencyCookie(initialCurrency);
      changeCurrency(initialCurrency);
    };

    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      currencySetup();
    } else {
      document.addEventListener("load", currencySetup);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSwitcherButtonClick = () => {
    setSwitcherIsOpen(!switcherIsOpen);
  };

  const handleCurrencyClick = (event, currency) => {
    const newCurrency = currencies[currency];
    setCurrentCurrency(newCurrency);
    setSwitcherIsOpen(false);
    changeCurrency(newCurrency);
    setCurrencyCookie(newCurrency);
  };

  if (!currentCurrency) return <></>;

  const currencyItems = Object.keys(currencies).map((key, index) => {
    const currency = currencies[key];

    return (
      <li
        className={`${styles.currencyItem}`}
        key={currency?.code}
        onClick={(event) => handleCurrencyClick(event, currency?.code)}
      >
        <span className={`${styles.symbol}`}>{currency?.symbol}</span>
        <span className={`${styles.name}`}>{currency?.fullName}</span>
        <span className={`${styles.flag}`}>
          {currency?.country && (
            <Image
              src={`/assets/img/icons/flags/${currency.country}.svg`}
              width="24"
              height="18"
              // fill
              // style={{ width: "24px", height: "18px" }}
              alt={`${currency.country} flag`}
            />
          )}
        </span>
      </li>
    );
  });

  return (
    <>
      <button
        className={`${styles.currencySwitcherButton} ${
          switcherIsOpen ? styles.active : ""
        }`}
        style={buttonStyles}
        onClick={handleSwitcherButtonClick}
      >
        <span className={`${styles.buttonWrapper}`}>
          {/* {currentCurrency?.symbol} {currentCurrency?.code.toUpperCase()} */}
          <span className={`${styles.flag}`}>
            {currency.country && (
              <Image
                src={`/assets/img/icons/flags/${currency.country}.svg`}
                width="24"
                height="18"
                style={{ width: "24px", height: "18px" }}
                // fill
                alt={`${currency.country} flag`}
                loading="eager"
                // layout="fixed"
              />
            )}
          </span>
        </span>
      </button>
      <div
        className={`${styles.currencySwitcher} ${
          switcherIsOpen ? styles.show : styles.hide
        }`}
        style={listStyles}
      >
        <ul className={`${styles.currencyItems}`}>{currencyItems}</ul>
      </div>
    </>
  );
};

export default CurrencySwitcher;
