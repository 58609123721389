import { useEffect, useState } from "react";

function useDisableScroll(initialDisable = false, rootId) {
  const [disabled, setDisabled] = useState(initialDisable);

  useEffect(() => {
    if (!disabled) {
      return;
    }

    const root = document?.getElementById(rootId) ?? "__next";

    // Save initial body style
    const originalOverflow = document.body.style.overflow;
    const originalWidth = document.body.clientWidth;
    const originalMargin = document.body.style.margin;

    // disable body scroll
    document.body.style.overflow = "hidden";

    // Get the scrollBar width
    const newWidth = document.body.clientWidth;
    const scrollBarWidth = newWidth - originalWidth;

    // Avoid width reflow

    if (originalWidth > 768) {
      document.body.style.marginRight = `${scrollBarWidth}px`;
    }

    return () => {
      document.body.style.overflow = originalOverflow;

      if (scrollBarWidth) {
        document.body.style.marginRight = originalMargin;
      }
    };
  }, [disabled, rootId]);

  // Update state if initialValue changes
  useEffect(() => {
    if (disabled !== initialDisable) {
      setDisabled(initialDisable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDisable]);

  return [disabled, setDisabled];
}

export default useDisableScroll;
