import React from "react";

const useOutsideClick = (callback, exceptions) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      const isException =
        typeof exceptions !== "undefined"
          ? exceptions.find((exception) => {
              if (
                exception?.current &&
                exception.current !== null &&
                !!ref?.current
              ) {
                return exception?.current?.contains(event.target);
              }
            })
          : false;

      if (
        ref?.current &&
        ref.current.tagName &&
        !isException &&
        !ref.current.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, { capture: true });

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback, exceptions]);

  return ref;
};

export default useOutsideClick;
