import { useState } from "react";
import dynamic from "next/dynamic";
const NavLinks = dynamic(() =>
  import("components/layoutComponents/Nav/NavLinks/NavLinks")
);
const SocialLinks = dynamic(() =>
  import("components/links/SocialLinks/SocialLinks")
);
const CurrencySwitcher = dynamic(() =>
  import("components/widgets/currency/CurrencySwitcher/CurrencySwitcher")
);
const UserButtonComponent = dynamic(() =>
  import("components/ui/Account/UserButton/UserButton")
);

import { MdOutlineMenu } from "react-icons/md";

import useOutsideClick from "/lib/hooks/useOutsideClick";
import useDisableScroll from "/lib/hooks/useDisableScroll";

import styles from "./HamburgerMenu.module.scss";

export default function HamburgerMenu({ socialLinks }) {
  const [open, setOpen] = useState(false);

  const hamburgerRef = useOutsideClick(() => setOpen(false), []);

  useDisableScroll(open, "__next");

  const handleIconClick = () => {
    setOpen((previous) => !previous);
  };
  return (
    <div className={`${styles.hamburgerMenu}`} ref={hamburgerRef}>
      <div className={`${styles.icon}`} onClick={handleIconClick}>
        <MdOutlineMenu size={30} />
      </div>
      <div
        className={`${styles.menuContent} ${
          open ? styles.open : styles.closed
        }`}
      >
        <NavLinks mobile={true} />
        <div className={`${styles.row1}`}>
          <div className={`${styles.currencySwitcher}`}>
            <CurrencySwitcher
              buttonStyles={{
                color: "var(--dark-text)",
                height: "auto",
                minHeight: "40px",
                bottom: "0",
              }}
              listStyles={{
                top: "75px",
                right: "0px",
              }}
            />
          </div>

          <div className={`${styles.userButton}`}>
            <UserButtonComponent />
          </div>
        </div>
        <div className={`${styles.menuInfo}`}>
          <SocialLinks
            omit={["email", "messenger", "whatsapp"]}
            links={socialLinks}
            itemStyles={{ padding: "0 var(--spacing-xs)" }}
          />
        </div>
      </div>
    </div>
  );
}
