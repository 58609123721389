import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const ddConfig = {
  applicationId: process.env.NEXT_PUBLIC_DD_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
  service: process.env.NEXT_PUBLIC_DD_SERVICE,
  env: process.env.NEXT_PUBLIC_DD_ENV,
  version: process.env.NEXT_PUBLIC_VERSION,
};

if (process.env.NEXT_PUBLIC_DATADOG_ENABLED) {
  const common = {
    ...ddConfig,
    site: "datadoghq.eu",
    sessionSampleRate: 100,
  };

  datadogLogs.init({
    ...common,
    forwardErrorsToLogs: true,
  });

  datadogRum.init({
    ...common,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
