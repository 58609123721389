import { CustomSearchBoxDummy } from "components/ui/search/CustomSearchBox/CustomSearchBox";
import useWindowSize from "lib/hooks/useWindowSize";
import { useRouter } from "next/router";
import styles from "./HeaderSearch.module.scss";

export default function HeaderSearchDummy({
  onClick,
  hideSearchOnMobilePages = [],
  hide = false,
}) {
  const windowSize = useWindowSize();
  const router = useRouter();

  if (
    hideSearchOnMobilePages.find(
      (page) => router.pathname.replace("/", "") === page
    ) &&
    windowSize.width <= 768
  ) {
    return <></>;
  }

  return (
    <div className={`${styles.searchBoxWrapper} ${hide ? styles.hide : ""}`}>
      <div className={`${styles.searchBox}`}>
        <CustomSearchBoxDummy
          placeholder="Find your experience"
          initialSearch="asia"
          initialOpen={true}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
