export const categories = [
  {
    category: "Animals",
    slug: "/animals",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1658846773/costa_rica_turtle_large_8e1a14efe6.jpg",
  },
  {
    category: "Adventure",
    slug: "/adventure",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1657905658/nepal_adventure_metamedia_bb0b24cf0d.jpg",
  },
  {
    category: "Internships",
    slug: "/internships",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1657194184/costa_rica_turtle_metamedia_733e4ff361.jpg",
  },
  {
    category: "Learning",
    slug: "/learning",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1657810859/thailand_monk_temple_large_a88be5f479.jpg",
  },
  {
    category: "Teaching",
    slug: "/teaching",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1657193340/costa_rica_spanish_large_5414d0029d.jpg",
  },
  {
    category: "Volunteering",
    slug: "/volunteering",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1658182794/philippines_volunteerclass_large_e111c99f9f.jpg",
  },
  {
    category: "Yoga",
    slug: "/yoga",
    image:
      "https://res.cloudinary.com/dail4fbms/image/upload/c_fill,h_198,w_374,f_auto,q_auto/v1657799936/thailand_beachyoga_large_4c30cabc64.jpg",
  },
];
