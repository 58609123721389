import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "contexts/user";
import { getCookie } from "utils";

export function useCart() {
  const {
    userData,
    setUserData,
    refreshCart: refreshCartContext,
    isCartUpdating,
  } = useContext(UserContext);
  let { cart: currentCart } = userData;
  const { items: cartItems } = currentCart;

  async function refreshCart() {
    const response = await refreshCartContext();
    return response;
  }

  async function addExperienceToCart({ formData }) {
    try {
      const metadata = formData.product_metadata;

      const processedFormData = {
        ...formData,
        type: "experience",
        product_metadata: {
          ...metadata,
          type: "experience",
        },
        id: metadata.id,
      };

      const response = await addSingleToCart({
        product: processedFormData,
        type: "experience",
      });

      return response;
    } catch (error) {
      console.error(error);
      return { error: true, message: JSON.stringify(error) };
    }
  }

  async function addSingleToCart({ product, type }) {
    try {
      const updatedCartItems = [...cartItems, product];
      const lastModified = Date.now();

      const updatedCartData = {
        lastModified,
        items: updatedCartItems,
      };

      setUserData((prev) => {
        return {
          ...prev,
          cart: {
            ...prev.cart,
            ...updatedCartData,
          },
        };
      });

      let response;

      const updatedCart = { cart: { items: [product], lastModified } };

      // response = await sendCartToDatabase({
      //   cart: updatedCart,
      //   setUserData,
      // });
      response = await sendItemToDatabaseCart({ item: product });

      setUserData((prev) => {
        return {
          ...prev,
          cart: {
            ...prev.cart,
            ...response,
          },
        };
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  }

  function addMultipleToCart({ products, type }) {
    console.log(
      `Adding ${products.length} ${type} to cart: ${products.map(
        (product) => product?.id ?? "Missing ID"
      )}`
    );
    try {
      const newProducts = [...products];

      const updatedCartItems = [...cartItems, ...newProducts];
      const lastModified = Date.now();

      const updatedCartData = {
        lastModified: lastModified,
        items: updatedCartItems,
      };

      setUserData((prev) => {
        return {
          ...prev,
          cart: {
            ...prev.cart,
            ...updatedCartData,
          },
        };
      });

      sendCartToDatabase({
        cart: {
          ...currentCart,
          ...updatedCartData,
        },
        setUserData,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function updateItem({ cartId, type, updateObject }) {
    const updateItemIndex = cartItems.findIndex((item) => {
      return item.cartId === cartId;
    });

    const updatedItem = {
      ...cartItems[updateItemIndex],
      ...updateObject,
    };

    const updatedCartItems = [...cartItems];
    updatedCartItems[updateItemIndex] = updatedItem;

    setUserData((prev) => {
      return {
        ...prev,
        cart: {
          ...prev.cart,
          items: updatedCartItems,
        },
      };
    });

    const updateResponse = await updateDatabaseCartItem({
      item: updatedItem,
    });

    return updateResponse;
  }

  // useEffect(() => {
  //   console.log("Cart updated:", currentCart);
  // }, [currentCart]);

  async function deleteFromCart({ cartIds }) {
    const updatedCart = await deleteItemsFromDatabase({ cartIds, setUserData });

    const {
      cart_items: updatedCartItems,
      last_modified: updatedCartLastModified,
    } = await updatedCart;

    setUserData((prev) => {
      return {
        ...prev,
        cart: {
          ...prev.cart,
          items: updatedCartItems,
          lastModified: updatedCartLastModified,
        },
      };
    });
  }

  async function updatePromoCode({ promoCode }) {
    const response = await addPromoCodeToDatabaseCart({ promoCode });

    return response;
  }

  async function deletePromoCode({ promoCode }) {
    const response = await deletePromoCodeFromDatabaseCart({ promoCode });

    return response;
  }

  return {
    cart: currentCart,
    addSingleToCart,
    addExperienceToCart,
    updateItem,
    deleteFromCart,
    refreshCart,
    isCartUpdating,
    updatePromoCode,
    deletePromoCode,
  };
}

const addPromoCodeToDatabaseCart = async ({ promoCode }) => {
  const tempUserId = getCookie("temp_user_id");

  try {
    const promoCodeResponse = await fetch(`/api/user/cart/promo-code`, {
      method: "POST",
      body: JSON.stringify({
        promoCode,
        tempUserId,
      }),
    });

    const promoCodeData = await promoCodeResponse.json();

    return promoCodeData;
  } catch (error) {
    console.error(error);
  }
};

const deletePromoCodeFromDatabaseCart = async ({ promoCode }) => {
  const tempUserId = getCookie("temp_user_id");

  try {
    const promoCodeResponse = await fetch(
      `/api/user/cart/promo-code?tempUserId=${tempUserId}&promoCode=${promoCode}`,
      {
        method: "DELETE",
      }
    );

    const promoCodeData = await promoCodeResponse.json();

    return promoCodeData;
  } catch (error) {
    console.error(error);
  }
};

async function deleteItemsFromDatabase({ cartIds, setUserData }) {
  const tempUserId = getCookie("temp_user_id");

  const response = await fetch(
    `/api/user/cart?ids=${cartIds}${
      tempUserId ? `&tempUserId=${tempUserId}` : ""
    }`,
    {
      method: "DELETE",
    }
  );

  const data = await response.json();

  const { updatedCart } = await data.response;

  return updatedCart;
}

async function updateDatabaseCartItem({ item }) {
  const tempUserId = getCookie("temp_user_id");

  try {
    const response = await fetch(`/api/user/cart`, {
      method: "PATCH",
      body: JSON.stringify({
        item,
        isTempUser: !!tempUserId,
        tempUserId,
      }),
    });
    const data = await response.json();

    if (!!data.error) {
      console.error(data.error);
      return;
    }

    return data;
  } catch (error) {
    console.error(error);
  }
}

async function sendItemToDatabaseCart({ item }) {
  const tempUserId = getCookie("temp_user_id");

  try {
    const response = await fetch(`/api/user/cart`, {
      method: "POST",
      body: JSON.stringify({
        item,
        isTempUser: !!tempUserId,
        tempUserId,
      }),
    });
    const data = await response.json();

    if (!!data.error) {
      console.error(data.error);
      return;
    }

    return data;
  } catch (error) {
    console.error(error);
  }
}

// Send cart to Strapi
async function sendCartToDatabase({ cart, setUserData }) {
  const tempUserId = getCookie("temp_user_id");

  try {
    const response = await fetch(`/api/user/cart`, {
      method: "POST",
      body: JSON.stringify({
        cart,
        isTempUser: !!tempUserId,
        tempUserId,
      }),
    });
    const data = await response.json();

    if (!!data.error) {
      console.error(data.error);
      return;
    }

    return data;
  } catch (error) {
    console.error(error);
  }
}
