import { useState, useContext } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import UserButtonComponent from "@/components/ui/Account/UserButton/UserButton";
import { useUser, useAuth } from "@clerk/nextjs";
import { useCart } from "lib/hooks/useCart";

import { BsBag } from "react-icons/bs";
import HeartIcon from "/public/assets/img/icons/heart.svg";
import HeartIconFill from "/public/assets/img/icons/heart-fill.svg";

import NavLinks from "../Nav/NavLinks/NavLinks";
import HamburgerMenu from "components/ui/HamburgerMenu/HamburgerMenu";
import SignUpSignInModal from "@/components/auth/clerk/SignUpSignInModal";

const HeaderSearch = dynamic(
  () => import("components/ui/search/HeaderSearch/HeaderSearch"),
  { ssr: false }
);

import HeaderSearchDummy from "components/ui/search/HeaderSearch/HeaderSearchDummy";
import CurrencySwitcher from "components/widgets/currency/CurrencySwitcher/CurrencySwitcher";

import { UserContext } from "contexts/user";
import styles from "./Header.module.scss";

export default function Header({ headerData, socialLinks }) {
  const [headerSearchActive, setHeaderSearchActive] = useState(false);
  const [headerSearchLoaded, setHeaderSearchLoaded] = useState(false);

  const { user } = useUser();
  let username = user?.username;

  const { isSignedIn } = useAuth();

  if (!user?.username) {
    if (user?.primaryEmailAddress?.emailAddress) {
      username = user?.primaryEmailAddress?.emailAddress;
    } else {
      username = user?.id;
    }
  }

  const { cart } = useCart();
  const { items } = cart;
  const cartItemsCount = items.length;

  const router = useRouter();

  const { userData } = useContext(UserContext);
  const { userFavorites } = userData;
  const { experiences: favoriteExperiences, activities: favoriteActivities } =
    userFavorites;

  const favoriteImageElement = (
    <Image
      src={
        !!favoriteExperiences?.length > 0 || !!favoriteActivities?.length > 0
          ? HeartIconFill
          : HeartIcon
      }
      alt="Heart-shaped favorite button"
      width={24}
      height={24}
    />
  );

  return (
    <header className={`${styles.mainHeader} variable-container`}>
      <div className={`${styles.headerElements}`}>
        <div className={`${styles.logoWrapper}`}>
          <Link href="/" aria-label="home" className={`${styles.logoLink}`}>
            <Image
              src="/assets/img/logo_full.svg"
              alt="logo"
              fill
              loading="eager"
              priority={true}
            />
          </Link>
        </div>

        <div className={`${styles.navLinks}`}>
          {headerData && <NavLinks mobile={false} headerData={headerData} />}
        </div>

        <div className={`${styles.headerSearch}`}>
          <HeaderSearchDummy
            onClick={() => setHeaderSearchActive(true)}
            hideSearchOnMobilePages={["experiences"]}
            hide={headerSearchActive}
          />

          {!!headerSearchActive && (
            <HeaderSearch
              hideSearchOnMobilePages={["experiences"]}
              isActive={true}
              initialOpen={true}
              onLoad={() => setHeaderSearchLoaded(true)}
            />
          )}
        </div>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.currencySwitcher} ${styles.button}`}>
            <CurrencySwitcher
              listStyles={{
                top: "65px",
                right: "-10px",
              }}
            />
          </div>
          <div className={`${styles.cartButton} ${styles.button}`}>
            <Link href="/cart">
              <BsBag size={22} color="var(--light-text)" />
              <span className={`${styles.cartButtonCount}`}>
                {cartItemsCount}
              </span>
            </Link>
          </div>

          <div className={`${styles.favoritesButton} ${styles.button}`}>
            {isSignedIn && (
              <Link
                href={
                  username
                    ? `/user/${username}${
                        router.asPath.includes("/user/")
                          ? ""
                          : "?section=favorites"
                      }`
                    : "/sign-up?redirect=/user/current?section=favorites"
                }
              >
                {favoriteImageElement}
              </Link>
            )}
            {!isSignedIn && (
              <SignUpSignInModal
                buttonText={favoriteImageElement}
                buttonMode="custom"
              />
            )}
          </div>

          <div className={`${styles.userButton} ${styles.button}`}>
            <UserButtonComponent mode="custom" />
          </div>
        </div>
      </div>

      <div className={`${styles.hamburgerMenu}`}>
        <HamburgerMenu socialLinks={socialLinks} />
      </div>
    </header>
  );
}
