import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { BiChevronDown } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";

const DestinationHeaderMenu = dynamic(
  () => import("components/DestinationPresentation/DestinationHeaderMenu/index")
);
const ExperienceHeaderMenu = dynamic(
  () =>
    import(
      "components/ExperiencePresentation/ExperienceHeaderMenu/ExperienceHeaderMenu"
    )
);
const DropdownMenu = dynamic(
  () => import("components/ui/DropdownMenu/DropdownMenu")
);

import { categories } from "lib/data/categories";

import styles from "./NavLinks.module.scss";

export default function NavLinks({ mobile, headerData }) {
  const [showDestinationMenu, setShowDestinationMenu] = useState(false);
  const [showExperiencesMenu, setShowExperiencesMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [showTravelGuideMenu, setShowTravelGuideMenu] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <nav className={`${styles.container}`}>
      <ul
        className={`${styles.nav} ${
          mobile ? styles.mobileNav : styles.desktopNav
        }`}
      >
        {!mobile && (
          <>
            <li
              className={` ${styles.menuItem} `}
              onMouseEnter={() => setShowExperiencesMenu(true)}
              onMouseLeave={() => setShowExperiencesMenu(false)}
            >
              <Link
                prefetch={false}
                href="/experiences"
                aria-label="experiences"
                className={`${styles.menuItemLink}`}
              >
                <>
                  Experiences
                  <BiChevronDown />
                </>
              </Link>
              {(loaded || showExperiencesMenu) && (
                <ExperienceHeaderMenu show={showExperiencesMenu} />
              )}
            </li>

            <li
              className={`${styles.menuItem} `}
              onMouseEnter={() => setShowDestinationMenu(true)}
              onMouseLeave={() => setShowDestinationMenu(false)}
            >
              <Link
                prefetch={false}
                href="/explore-travel-destinations"
                aria-label="destinations"
                className={`${styles.menuItemLink}`}
              >
                Destinations
                <BiChevronDown />
              </Link>

              {(loaded || showDestinationMenu) && (
                <DestinationHeaderMenu
                  show={showDestinationMenu}
                  featuredDestinations={headerData?.headerDestinations ?? null}
                />
              )}
            </li>
          </>
        )}
        {mobile && (
          <>
            <li
              className={` ${styles.menuItem} ${styles.subMenuParent} ${
                showExperiencesMenu ? styles.active : ""
              }`}
              onClick={() => setShowExperiencesMenu((previous) => !previous)}
            >
              <span>
                Experiences
                <BiChevronRight
                  style={{ fontSize: "xx-large" }}
                  className={`${styles.menuArrow} ${
                    showExperiencesMenu ? styles.activeArrow : ""
                  }`}
                />
              </span>
              <ExperienceCategoryMenu
                show={showExperiencesMenu}
                setShowExperiencesMenu={setShowExperiencesMenu}
              />
            </li>
            <li
              className={` ${styles.menuItem} ${styles.subMenuParent} ${
                showDestinationMenu ? styles.active : ""
              }`}
              onClick={() => setShowDestinationMenu((previous) => !previous)}
            >
              <span>
                Destinations
                <BiChevronRight
                  style={{ fontSize: "xx-large" }}
                  className={`${styles.menuArrow} ${
                    showDestinationMenu ? styles.activeArrow : ""
                  }`}
                />
              </span>
              <DestinationMenu
                show={showDestinationMenu}
                setShowDestinationMenu={setShowDestinationMenu}
              />
            </li>
          </>
        )}
        {!mobile && (
          <li className={` ${styles.menuItem} `}>
            <DropdownMenu
              title="Travel Guides"
              titleSlug="/travel-guides"
              menuItems={[
                {
                  slug: "/travel-guides",
                  title: "Travel Guides",
                  ariaLabel: "travel-guides-index",
                },
                {
                  slug: "/travel-guides/create?clear=true",
                  title: "Create Guide",
                  ariaLabel: "create-travel-guide",
                },
              ]}
              containerClass={styles.menuItem}
            />
          </li>
        )}
        {mobile && (
          <>
            <li
              className={` ${styles.menuItem} ${styles.subMenuParent} ${
                showTravelGuideMenu ? styles.active : ""
              }`}
              onClick={() => setShowTravelGuideMenu((previous) => !previous)}
            >
              <span>
                Travel Guides
                <BiChevronRight
                  style={{ fontSize: "xx-large" }}
                  className={`${styles.menuArrow} ${
                    showTravelGuideMenu ? styles.activeArrow : ""
                  }`}
                />
              </span>
              <TravelGuideMenu
                show={showTravelGuideMenu}
                setShowMenu={setShowTravelGuideMenu}
              />
            </li>
          </>
        )}

        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href="/stories"
            aria-label="stories"
            className={`${styles.menuItemLink}`}
          >
            Stories
          </Link>
        </li>
        {/* <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href="/explore-travel-destinations"
            aria-label="map"
            className={`${styles.menuItemLink}`}
          >
            Map
          </Link>
        </li> */}
        {!mobile && (
          <li className={` ${styles.menuItem} `}>
            <DropdownMenu
              title="About"
              titleSlug="/about"
              menuItems={[
                {
                  slug: "/about",
                  title: "About Us",
                  ariaLabel: "about",
                },
                {
                  slug: "/about/partner-with-us",
                  title: "Partner With Us",
                  ariaLabel: "partner-with-us",
                },
                {
                  slug: "/about/corporate-social-responsibility",
                  title: "Corporate Social Responsibility",
                  ariaLabel: "corporate-social-responsibility",
                },
                {
                  slug: "/about/one-percent-for-the-planet",
                  title: "1% for the Planet",
                  ariaLabel: "one-percent-for-the-planet",
                },
                {
                  slug: "/about/one-tree-planted",
                  title: "One Tree Planted",
                  ariaLabel: "one-tree-planted",
                },
              ]}
              containerClass={styles.menuItem}
            />
          </li>
        )}
        {mobile && (
          <>
            <li
              className={` ${styles.menuItem} ${styles.subMenuParent} ${
                showAboutMenu ? styles.active : ""
              }`}
              onClick={() => setShowAboutMenu((previous) => !previous)}
            >
              <span>
                About
                <BiChevronRight
                  style={{ fontSize: "xx-large" }}
                  className={`${styles.menuArrow} ${
                    showAboutMenu ? styles.activeArrow : ""
                  }`}
                />
              </span>
              <AboutMenu
                show={showAboutMenu}
                setShowAboutMenu={setShowAboutMenu}
              />
            </li>
          </>
        )}

        {/* <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href="/about"
            aria-label="about"
            className={`${styles.menuItemLink}`}
          >
            About Us
          </Link>
        </li> */}
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href="/contact"
            aria-label="contact"
            className={`${styles.menuItemLink}`}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function DestinationMenu({ show, setShowDestinationMenu }) {
  const regions = [
    "africa",
    "asia",
    "caribbean",
    "central-america",
    "europe",
    "middle-east",
    "north-america",
    "oceania",
    "south-america",
  ];
  const regionLinks = regions.map((region) => (
    <li className={` ${styles.menuItem} `} key={region}>
      <Link
        prefetch={false}
        href={`/destinations/${region}`}
        aria-label={`${region}-link`}
        className={`${styles.subMenuLink} ${styles.menuItemLink}`}
      >
        {region.split("-").join(" ")}
      </Link>
    </li>
  ));
  return (
    <div className={`${styles.subMenu} ${show ? styles.open : styles.closed}`}>
      <ul>
        <li className={` ${styles.menuItem} `} style={{ fontWeight: "600" }}>
          <Link
            prefetch={false}
            href={`/explore-travel-destinations`}
            aria-label={`destinations-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            All
          </Link>
        </li>
        {regionLinks}
      </ul>
    </div>
  );
}

function ExperienceCategoryMenu({ show, setShowExperienceMenu }) {
  const categoryLinks = categories.map((categoryObject) => {
    const { category, slug } = categoryObject;
    return (
      <li className={` ${styles.menuItem} `} key={category}>
        <Link
          prefetch={false}
          href={`/experiences${slug}`}
          aria-label={`${category}-link`}
          className={`${styles.subMenuLink} ${styles.menuItemLink}`}
        >
          {category.split("-").join(" ")}
        </Link>
      </li>
    );
  });
  return (
    <div className={`${styles.subMenu} ${show ? styles.open : styles.closed}`}>
      <ul>
        <li className={` ${styles.menuItem} `} style={{ fontWeight: "600" }}>
          <Link
            prefetch={false}
            href={`/experiences/`}
            aria-label={`experiences-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            All
          </Link>
        </li>
        {categoryLinks}
      </ul>
    </div>
  );
}

function AboutMenu({ show }) {
  return (
    <div className={`${styles.subMenu} ${show ? styles.open : styles.closed}`}>
      <ul>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/about`}
            aria-label={`about-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            About Us
          </Link>
        </li>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/about/partner-with-us`}
            aria-label={`partner-with-us-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            Partner With Us
          </Link>
        </li>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/about/corporate-social-responsibility`}
            aria-label={`corporate-social-responsibility-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            Corporate Social Responsibility
          </Link>
        </li>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/about/one-percent-for-the-planet`}
            aria-label={`one-percent-for-the-planet-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            1% for the Planet
          </Link>
        </li>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/about/one-tree-planted`}
            aria-label={`one-tree-planted-link`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            One Tree Planted
          </Link>
        </li>
      </ul>
    </div>
  );
}

function TravelGuideMenu({ show }) {
  return (
    <div className={`${styles.subMenu} ${show ? styles.open : styles.closed}`}>
      <ul>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/travel-guides`}
            aria-label={`travel-guides-index`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            Travel Guides
          </Link>
        </li>
        <li className={` ${styles.menuItem} `}>
          <Link
            prefetch={false}
            href={`/travel-guides/create?clear=true`}
            aria-label={`create-travel-guide`}
            className={`${styles.subMenuLink} ${styles.menuItemLink}`}
          >
            Create Guide
          </Link>
        </li>
      </ul>
    </div>
  );
}
