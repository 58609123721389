export const currencies = {
  usd: {
    fullName: "United States Dollar",
    code: "usd",
    symbolCode: 36,
    symbol: "$",
    country: "united-states-of-america",
  },
  aud: {
    fullName: "Australian Dollar",
    code: "aud",
    symbolCode: 36,
    symbol: "$",
    country: "australia",
  },
  eur: {
    fullName: "Euro",
    code: "eur",
    symbolCode: 8364,
    symbol: "€",
    country: "european-union",
  },
  gbp: {
    fullName: "British Pound",
    code: "gbp",
    symbolCode: 163,
    symbol: "£",
    country: "united-kingdom",
  },
  sgd: {
    fullName: "Singapore Dollar",
    code: "sgd",
    symbolCode: 36,
    symbol: "$",
    country: "singapore",
  },
};

// export const currency = currencies.usd;
